import React, { useEffect, useState } from 'react';
import './shared.css';
import { useNavigate } from 'react-router-dom';
import Logo from './Images/Citro_logo.svg';
import ReactGA from 'react-ga4';
import { goals } from '../Config/settings.js';
import { useDispatch } from 'react-redux';
import { setGoal, setCdrid, setExisting } from '../redux/slices/userSlice.js'


function EntryPage() {
const dispatch = useDispatch();

    useEffect(() => {
  
ReactGA.send({ hitType: "pageview", page: window.location.pathname });
      }, []);

  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  console.log('welcome to rewards optimiser');

  //handle url para - for web/mobile.

        const handleSelectGoal = (goal) => {
        dispatch(setGoal(goal));
        setIsSubmitting(true);
        navigate('/WeCanHelp');
        
      };

         
      const queryParams = new URLSearchParams(window.location.search);
      const cdrid = queryParams.get('cdrid');
      const existingValue = queryParams.get('existing') || 'false'; 
    
      dispatch(setCdrid(cdrid));
      dispatch(setExisting(existingValue));
   
    

return (
<div className="container">
<div className="logo-box">
    {<img src={Logo} alt="Logo" className="logo-image" />}
        
      </div>
  <div className="header">
    <h1 className="title">What is your primary money goal?</h1>
  <div className="card-box-container">
      {goals.map((goal, index) => (
        <div
          key={index}
          className={`card-box`}
          onClick={() => handleSelectGoal(goal)}
        >
          I want to {goal}
        </div>
      ))}
    </div>
    </div>



</div>

)
}

export default EntryPage;