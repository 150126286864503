import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../Components/shared.css';
import { headers } from '../../Config/settings.js';
import { apis } from '../../Config/apis.js';
import { setEmail } from '../../redux/slices/userSlice.js';
import { useSelector, useDispatch } from 'react-redux';


/*
Include tick box for T&CS
T&CS provided by Citro 
Citro wording for tickbox text.
only allow register if T&C is validated === true
*/

function RegisterEmail() {
  const dispatch = useDispatch();
  const [email, setEmailState] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isTcChecked, setIsTcChecked] = useState(false);
  const navigate = useNavigate();
  const goal = useSelector((state) => state.user.goal);


//testing
  const submitEmail = async (email) => {
   
    
    try {
      console.log(headers);
      const response = await fetch(apis.launchRo, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({ email }),
      });

      const responseData = await response.json();
     

      if (response.ok) {
   
        const urlString = responseData.roLink;
        const url = new URL(urlString);
        const params = url.searchParams;
        const existingValue = params.get('existing') || 'false';
        if ( existingValue !== 'successful' ) {
          const path = '/LaunchConsent';
          navigate(`${path}?${params.toString()}`);
            return;
        }

        // Use navigate from react-router-dom for navigation, avoiding window.location.href
        const path = '/Resume';
        navigate(`${path}?${params.toString()}`);
      } else {
        // Handle non-successful responses
        console.error('Error submitting email:', responseData.message);
        alert(responseData.message || 'Error submitting email');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred while submitting the email.');
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log("EMAIL:", email);
    setIsSubmitting(true);
    dispatch(setEmail(email));
    await submitEmail(email);
    
    if (window.analytics) {
      window.analytics.identify('f4ca124298', {
      reason: goal,
      email: email
    });
  }};

  return (
    <div className="form-container">
    <form onSubmit={handleSubmit} className="email-form-field">
      
      <input
        type="email"
        id="email"
        placeholder="Enter your email"
        value={email}
        onChange={(e) => setEmailState(e.target.value)}
        className="email-input"
        required
        autoComplete="email"
      />
      {/* Checkbox for Terms and Conditions */}
      <div className="tc-checkbox-container spaced">
          <input
            type="checkbox"
            id="tc-checkbox"
            checked={isTcChecked}
            onChange={(e) => setIsTcChecked(e.target.checked)} // Update state when checkbox is clicked
          />
          <label htmlFor="tc-checkbox">
         I agree to my personal information being collected and used in accordance with Citro's <a href="https://www.joincitro.com.au/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a> including being contacted. 
          </label>
        </div>

      <button className="submit-email-button" type="submit" disabled={isSubmitting} >
      {isSubmitting ? 'Launching...' : `Let's go`}</button>
    </form></div>
  );
}

export default RegisterEmail;
